import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

type IStylesParams = {
  pageBgColor: StyleParamType.Color;
  borderColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
  settingsBgColor: StyleParamType.Color;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
  fieldLabelColor: StyleParamType.Color;
  inputFieldsColor: StyleParamType.Color;
  fieldBorderColor: StyleParamType.Color;
  inputFieldsFont: StyleParamType.Font;
  fieldBorderWidth: StyleParamType.Number;
  fieldLabelFont: StyleParamType.Font;
  descriptionFont: StyleParamType.Font;
  descriptionColor: StyleParamType.Color;
  buttonTextFont: StyleParamType.Font;
  buttonBgColor: StyleParamType.Color;
  buttonBorderColor: StyleParamType.Color;
  buttonBorderWidth: StyleParamType.Number;
  pageTitleFont: StyleParamType.Font;
  pageTitleColor: StyleParamType.Color;
  sectionTitleFont: StyleParamType.Font;
  sectionTitleColor: StyleParamType.Color;
  fieldRowGap: StyleParamType.Number;
  fieldColGap: StyleParamType.Number;
  fieldsBgColor: StyleParamType.Color;
  dividerColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  settingsFont: StyleParamType.Font;
  settingsColor: StyleParamType.Color;
  buttonCornerRadius: StyleParamType.Number;
  inputCornerRadius: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  pageBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  settingsBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const pageBgColor = params.getStyleParamValue({
        key: 'pageBgColor',
        type: StyleParamType.Color,
      });

      if (pageBgColor) {
        return pageBgColor;
      }

      return wixColorParam('color-1', 0)(params);
    },
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  fieldLabelColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  fieldBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  fieldLabelFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      return wixFontParam('Body-M', {
        size: params.isMobile ? 14 : 16,
      })(params);
    },
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  settingsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  settingsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  buttonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const buttonBgColor = params.getStyleParamValue({
        key: 'buttonBgColor',
        type: StyleParamType.Color,
      });
      return buttonBgColor ? buttonBgColor : wixColorParam('color-8')(params);
    },
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      return wixFontParam('Page-title', {
        size: params.isMobile ? 20 : 28,
        style: {
          bold: false,
          italic: false,
          underline: false,
        },
      })(params);
    },
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      return wixFontParam('Heading-XL', {
        size: params.isMobile ? 16 : 20,
        style: {
          bold: false,
          italic: false,
          underline: false,
        },
      })(params);
    },
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  fieldRowGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  fieldBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  fieldColGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  fieldsBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  inputCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
