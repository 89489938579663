import React, { FC } from 'react';
import { Accordion, AccordionSize } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { PageAlignment } from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { useMember, useSiteContext } from '../../../../../contexts/widget';
import { useModal } from '../../../../../contexts/modals/hooks';
import { useBi } from '../../../../../hooks';
import settingsParams from '../../../settingsParams';
import { SectionHeader } from '../../common/SectionHeader';
import { useOpenBlockedModalOnce } from './hooks';
import {
  shouldShowBlockedMembersItem,
  shouldShowCommunityUrlItem,
  shouldShowProfileVisibilityItem,
} from './helpers';
import {
  getBlockedMembersItem,
  getCommunityPageUrlItem,
  getProfileVisibilityItem,
} from './AccordionItems';
import { classes, st } from './AccountSettings.st.css';

export const AccountSettings: FC = () => {
  const { t } = useTranslation();
  const { privacyStatus, role, blockedMembers, profile } = useMember();
  const { baseUrl, isSocial } = useSiteContext();
  const settings = useSettings();
  const biLogger = useBi();
  const { showModal } = useModal();

  useOpenBlockedModalOnce(blockedMembers.length, role);

  const pageAlignment = settings.get(
    settingsParams.pageAlignment,
  ) as PageAlignment;

  const accordionItems = [
    shouldShowCommunityUrlItem(isSocial, privacyStatus) &&
      getCommunityPageUrlItem({
        showModal,
        t,
        biLogger,
        baseUrl,
        slug: profile.slug,
      }),
    shouldShowProfileVisibilityItem(role) &&
      getProfileVisibilityItem({
        showModal,
        biLogger,
        t,
        privacyStatus,
      }),
    shouldShowBlockedMembersItem(role) &&
      getBlockedMembersItem({
        blockedMembers,
        showModal,
        t,
        biLogger,
        privacyStatus,
      }),
  ].filter((x) => x) as JSX.Element[];

  return (
    <div
      data-hook={DataHook.MyAccountSettings}
      className={st(classes.root, {
        alignCenter: pageAlignment === PageAlignment.center,
      })}
    >
      <SectionHeader
        title={settings.get(settingsParams.settingsSectionTitle)}
        subtitle={settings.get(settingsParams.settingsSectionSubtitle)}
        className={classes.sectionHeader}
      />
      <Accordion
        multiple={true}
        size={AccordionSize.Large}
        className={classes.accordion}
      >
        {accordionItems}
      </Accordion>
    </div>
  );
};
