import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  Text,
  TextPriority,
  Button,
  ButtonPriority,
  TextField,
} from 'wix-ui-tpa/cssVars';
import { debounce } from 'lodash';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { DataHook } from '../../../../../constants/DataHook';
import { ModalType } from '../../../../../types';
import {
  useMember,
  useMemberActions,
  useSiteContext,
  useUiActions,
  useUiState,
} from '../../../../../contexts/widget';
import { useBi } from '../../../../../hooks';
import { LoadingIcon } from '../../common/icons/Loading/Loading';
import { classes, st } from './EditProfileUrlModal.st.css';

const MAX_SLUG_LENGTH = 255;

export const EditProfileUrlModal: FC<ModalType> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const { updateSlug } = useMemberActions();
  const initialSlug = useMember().profile.slug;
  const { isAvailable, isLoading } = useUiState().slug;
  const { querySlugAvailability, setSlugAvailability, setSlugLoading } =
    useUiActions();
  const [newSlug, setNewSlug] = useState(initialSlug);
  const [error, setError] = useState<string | null>();
  const [isValid, setIsValid] = useState(true);
  const { baseUrl } = useSiteContext();
  const biLogger = useBi();

  const isFieldChanged = newSlug !== initialSlug;
  const isConfirmDisabled = !isFieldChanged || !!error || isLoading;

  useEffect(() => {
    if (newSlug === initialSlug) {
      setError(null);
    } else if (!isValid) {
      setError(t('app.widget.modals.change-profile-url.invalid-input'));
    } else if (!isAvailable) {
      setError(t('app.widget.modals.change-profile-url.slug-not-available'));
    } else {
      setError(null);
    }
  }, [isAvailable, t, newSlug, initialSlug, isValid]);

  const checkSlugAvailability = useMemo(
    () => debounce(querySlugAvailability, 500),
    [querySlugAvailability],
  );

  const resetErrors = () => {
    setSlugAvailability(true);
    setError(null);
  };

  const onConfirm = async () => {
    updateSlug(newSlug);
    biLogger.profileEdited({ urlChanged: true });
    resetErrors();
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        resetErrors();
        onClose();
      }}
      contentClassName={st(classes.root, { mobile: isMobile, rtl: isRTL })}
      childrenWrapperClassName={classes.content}
      data-hook={DataHook.EditProfileUrlModal}
      wiredToSiteColors={false}
    >
      <div className={classes.section}>
        <Text className={classes.title} tagName="div">
          {t('app.widget.modals.change-profile-url.title')}
        </Text>
      </div>

      <div className={st(classes.section, classes.mainContent)}>
        <Text className={classes.mainText} tagName="div">
          {t('app.widget.modals.change-profile-url.content')}
        </Text>
        <TextField
          label={t('app.widget.modals.change-profile-url.input-label')}
          value={newSlug}
          maxLength={MAX_SLUG_LENGTH}
          className={classes.urlInput}
          inputClassName={classes.inputRoot}
          data-hook={DataHook.EditProfileUrlSlugInput}
          onChange={(changeEvent) => {
            const newValue = changeEvent.target.value;
            const isInputValid = /^[a-z0-9-]+$/i.test(newValue);

            if (isInputValid) {
              setIsValid(true);
              setSlugLoading(true);
              checkSlugAvailability(newValue);
            } else {
              setIsValid(false);
              checkSlugAvailability.cancel();
              setSlugLoading(false);
            }

            setNewSlug(newValue);
          }}
          suffix={isLoading && <LoadingIcon />}
          errorMessage={error ?? ''}
          error={!isLoading && !!error}
          success={isFieldChanged && !isLoading && !error}
          successIcon={isFieldChanged && !isLoading && !error}
        />
        <div className={classes.fullUrlContainer}>
          <Text className={classes.baseUrl} priority={TextPriority.secondary}>
            {baseUrl + '/profile/'}
          </Text>
          <Text className={classes.slug}>{newSlug}</Text>
          {newSlug && (
            <Text className={classes.baseUrl} priority={TextPriority.secondary}>
              /profile
            </Text>
          )}
        </div>
      </div>

      <div className={classes.separator}></div>

      <div className={classes.section}>
        <div className={classes.ctaContainerOuter}>
          <div className={classes.ctaContainer}>
            <Button
              upgrade
              priority={ButtonPriority.basicSecondary}
              className={st(classes.button, { secondary: true })}
              onClick={onClose}
            >
              {t('app.widget.modals.change-profile-url.discard')}
            </Button>
            <Button
              upgrade
              className={st(classes.button, {
                primary: true,
                disabled: isConfirmDisabled,
              })}
              onClick={onConfirm}
              disabled={isConfirmDisabled}
            >
              {t('app.widget.modals.change-profile-url.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
